@import './bootstrap-reboot.css';
@import './common.scss';
@import './header.scss';
@import './footer.scss';

.row-divider {
  width: 100%;
  height: 100px;
}

.subtitle {
  font-size: 20px;
  margin: 30px 20px 45px 20px;
}

.top-section {
  text-align: center;
  padding-top: 110px;
  padding-bottom: 120px;

  .shout-title {
    margin-top: 10px;
    font-size: 46px;
    font-weight: 600;
  }

  .shout-subtitle {
    font-size: 23px;
    margin: 20px 0px 25px 0px;
    color: #333333;
  }

  .download-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto 40px auto;

    a {
      background-image: linear-gradient(to right, #3572e3 , #05c2da);
      border-radius: 4px;
      color: white;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
      color: white;
      padding: 10px 60px;
    }
  }

  .available-on {
    font-size: 17px;
    color: #666666;
  }

  .main-image {
    margin-top: 50px;
  }
}

.feature-section {
  text-align: center;
  padding-top: 110px;
  padding-bottom: 120px;
}

.feature-section-alternate {
  @extend .feature-section;
  background-color: #FAFAFA;
}

.row-text {
  width: 45%;
}

.row-img {
  width: 46%;
}

.benefit-details * {
  text-align: left !important;
}
.benefit-title {
  font-size: 28px;
  font-weight: 600;
  img {
    height: 30px;
    margin-right: 8px;
    margin-top: -6px;
  }
}

.benefit-subtitle {
  font-size: 18px;
  color: #666666;
  margin-top: 30px;
}

.organize-section {
  padding-top: 80px;
  padding-bottom: 80px;
  h1 {
    text-align: center;
  }
}

.sync-section {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #0078C5;

  h1 {
    text-align: center;
  }

  * {
    color: white;
  }
}

.web-clipper-wrapper {
  max-width: 600px;
  margin: 40px auto 0px auto;
  display: flex;
  justify-content: space-around;
}

.get-upnote-section {
  font-size: 18px;
}

.download-stores-outer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
  padding: 60px 20px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 24px 0px rgba(220,220,220,0.75);

  .store-badge {
    height: 48px;
    border-radius: 6px;
    margin: 10px 0px;
  }

  h2 {
    font-size: 25px;
  }

  h3 {
    font-size: 20px;
  }
}

.download-stores-item {
  width: 30%;
  margin-top: 10px;
  flex-basis: 30%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.download-installer-btn {
  border: 1.5px solid #127ac3;
  color: #127ac3;
  font-weight: 500;
  width: 136px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.view-instruction {
  color: #888888;
  margin-top: 5px;
  font-size: 16px;
}

.pricing-wrapper {
  div {
    font-size: 18px;
  }
}

.user-review {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 60px;

  .user-review-item {
    width: 31%;
    background-color: #FAFAFA;
    text-align: start;
    padding: 20px;
    border-radius: 16px;
    font-size: 15px;
    margin-bottom: 60px;

    .user-review-item-title {
      font-weight: 600;
    }

    .user-review-item-subtitle {
      margin-top: 5px;
    }

    .user-review-item-content {
      margin-top: 25px;
    }

    .user-review-item-stars {
      margin-left: 3px;
      img {
        margin-left: -3px;
      }
    }
  }
}

.view-more-on-app-store-wrapper {
  margin-top: 30px;
}

.border-btn {
  padding: 7px 16px;
  border: 1.5px solid #0078C5;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  color: #0078C5;
}

.border-btn:hover {
  border-color: #0056b3;;
}

.premium-benefit-details-wrapper {
  justify-content: center;
  display: flex;
  margin: 40px 0px;

  .premium-benefit-inner {
    text-align: left;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px 30px;
  }

  .premium-benefit-detail {
    display: flex;
    align-items: center;
    margin: 10px 0px;

    img {
      width: 26px;
      height: 26px;
      margin-right: 16px;
    }
  }
}

@media (max-width: 767px) {
  .row-text {
    width: 100%;
    margin-top: 10px;
  }

  .row-img {
    width: 100%;
  }

  .float-left, .float-right {
    float: none !important;
    text-align: center;
  }

  .benefit-subtitle {
    margin: 20px auto 30px auto !important;
  }

  .benefit-img {
    margin: 10px auto !important;
    display: block;
  }

  .row-divider {
    height: 50px;
  }

  .web-clipper-wrapper {
    display: block;

    .border-btn {
      display: block;
      margin: 10px 0px;
    }
  }

  .user-review {
    flex-direction: column;
    .user-review-item {
      width: auto;
      margin-block-start: 20px;
      margin-block-end: 20px;
    }
  }

  .download-stores-outer {
    flex-direction: column !important;
  }

  .download-stores-item {
    width: auto;
    margin-block-start: 20px;
    margin-block-end: 20px;
  }
}